import Home from './home'
import IndexPage from './indexPage'
import Projet from './projet'
import Lock from './lock'
import Information from'./information'

const sections = [
	new Home,
	new Projet,
	new IndexPage,
	new Lock,
	new Information

]

export default sections
