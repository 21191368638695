import Default from './default'
import configQ from '../utils/func/configQ'
import config from '../config'
import InView from 'inview'


class Projet extends Default {
	constructor() {
		super('projet')
	}
	mounted() {
        configQ(window.innerWidth)
        this.cache()
        this.init()

    }

    cache() {

        this.thumbs = [...document.querySelectorAll('.figure')]

    }

    init() {
        
        sessionStorage.setItem('projet', window.location.pathname.substr(9));
        document.querySelector('html').classList.add('intro_done')
        document.body.style.scrollSnapType = 'none'
        document.querySelector('html').style.scrollSnapType = 'none'
        document.body.classList.remove('snap')
        document.querySelector('html').classList.remove('snap')
        window.scrollTo(0,0,0)

        document.querySelector('footer').classList.add('absolute')
        setTimeout(() => {
            document.querySelector('footer').style.opacity = 1
        },300)

        this.initMonitor()


    }

    initMonitor() {

        this.thumbs.forEach((thumb) => {

            InView(thumb, function(isInView) {

                if (isInView) {
                    
                    const images = [...thumb.querySelectorAll('img')]
                    images.forEach((image) => {
                        if(image && image?.dataset.src) {
                            image.src = image?.dataset.src
                            image.removeAttribute('data-src')
                            image.classList.add('visualised')
                        }
                    })

                    thumb.classList.add('visualised')
                    this.destroy()

                }
            })

        })

    }

	beforeDestroy() {



    }

	resize(w) {

		const oldConfig = config.isMobile        
        configQ(w)
        if (oldConfig !== config.isMobile) {
            this.addEvents('off')
            this.mounted()
        }

	}
}

export default Projet