import Default from './default'
import configQ from '../utils/func/configQ'
import config from '../config'

class Lock extends Default {
	constructor() {
		super('lock')
	}
	mounted() {

        configQ(window.innerWidth)
        this.cache()

    }

    cache() {

        sessionStorage.setItem('projet', window.location.search.substr(20).toLowerCase());
        document.body.classList.remove('snap')
        document.querySelector('html').classList.remove('snap')
        document.body.style.scrollSnapType = 'none'
        document.querySelector('html').style.scrollSnapType = 'none'
        window.scrollTo(0,0,0)

    }

	resize(w) {

		const oldConfig = config.isMobile        
        configQ(w)
        if (oldConfig !== config.isMobile) {
            this.mounted()
        }

	}
}

export default Lock