import gsap from "gsap"

export default function foot(oldContainer, newContainer, done) {
	
    const tl = gsap.timeline()

    tl.to(oldContainer, {
        duration: 0.3,
        opacity: 0,
        ease: "power2.out",
    })
    tl.to(document.querySelector('footer'), {
        duration: 0.2,
        opacity: 0,
        ease: "power2.out",
    }, "-=0.6")
    tl.to(oldContainer, {
        duration: 0,
        display: 'none'
    })
    tl.to(document.querySelector('footer'), {
        duration: 0,
        position: 'absolute',
    })
    tl.then(() => { window.scrollTo(0,0) })
    tl.to(newContainer, {
        duration: 0,
        opacity : 0,
        visibility: 'visible'
    })
    tl.to(document.body, {
        duration: 0,
        scrollSnapType: 'none'
    })
    tl.to(document.querySelector('html'), {
        duration: 0,
        scrollSnapType: 'none'
    })
    tl.to(document.querySelector('footer'), {
        duration: 0.3,
        opacity: 1
    })
    tl.then(() => { window.scrollTo(0,0) })
    tl.to(newContainer, {
        duration: 1,
        opacity: 1,
        ease: "power2.in",
    })
    tl.then(() => { window.scrollTo(0,0) })
    
    tl.then(done)

}