import select from 'dom-select'
import bind from 'es6-class-bind-all'
import event from 'dom-event'
import InView from 'inview'
import $ from 'jquery'

class Nav {
	constructor() {
		bind(this)

		this.cache()
		this.init()
	}
	cache() {

		this.sliderColor = select('.slider_color')
		this.informationPart = document.querySelector('#infos_part')
        this.informationButton = document.querySelector('.navigation_item[data-menu="information"')
        this.indexButton = document.querySelector('.navigation_item[data-menu="index"')
		
	}

	init() {
		
		event['on'](this.sliderColor, 'click', this.toggleSliderColor)
		this.setColor()
        
    }

	setColor() {

		const mode = sessionStorage.getItem('mode')
		!mode || mode == 'light'
		? document.querySelector('html').classList.remove('dark_mode')
		: document.querySelector('html').classList.add('dark_mode')

	}

	toggleSliderColor() {

		if(document.querySelector('html').classList.contains('dark_mode')) {

			document.querySelector('html').classList.remove('dark_mode')
			sessionStorage.setItem('mode', 'light')

		} else {

			sessionStorage.setItem('mode', 'dark')
			document.querySelector('html').classList.add('dark_mode')

		}

	}

}

export default Nav