import Default from './default'
import configQ from '../utils/func/configQ'
import config from '../config'
import gsap from 'gsap'

class Information extends Default {
	constructor() {
		super('information')
	}
	mounted() {

        configQ(window.innerWidth)
        this.cache()
        this.init()

    }

    cache() {

        document.querySelector('html').classList.add('intro_done')
        this.footer = document.querySelector('footer')

    }

    init() {
        
        this.footer.classList.remove('absolute')
        this.footer.style.position = 'fixed'
        gsap.timeline()
        .to(this.footer, {
            duration: 1,
            opacity: 1,
            y: 0
        }, '+=0.3')

    }


    addEvents(type) {

    }

   

	beforeDestroy() {

		this.addEvents('off')

    }

	resize(w) {

		const oldConfig = config.isMobile        
        configQ(w)
        if (oldConfig !== config.isMobile) {
            this.addEvents('off')
            this.mounted()
        }

	}
}

export default Information