import Default from './default'
import configQ from '../utils/func/configQ'
import config from '../config'
import event from 'dom-event'
import gsap from 'gsap'
import {ScrollToPlugin} from 'gsap/dist/ScrollToPlugin'
import chargeImageBack from '../utils/chargeImageBack'


class Home extends Default {
	constructor() {
		super('home')
	}
	mounted() {
        configQ(window.innerWidth)
        this.cache()
        this.init()

    }

    cache() {

        if(sessionStorage.getItem('intro') == 'true') {
            document.querySelector('.intro').remove()
            document.querySelector('html').classList.add('intro_done')
        }

        this.thumbs = [...document.querySelectorAll('.figure')]
        this.thumbsWrapper = [...document.querySelectorAll('.figure_wrapper')]
        this.footer = document.querySelector('.footer')
        this.backImage = [...document.querySelectorAll('.background_image')]

        this.wait = false
        this.set

        this.countImage = 1

        this.scroll = 0
        gsap.registerPlugin(ScrollToPlugin);

    }

    init() {

        this.initImage()
        const project = document.querySelector(`.figure_wrapper a[href="projets/${sessionStorage.getItem('projet')}"]`)
        if(project) {
            const tl = gsap.timeline()
            .to(document.querySelector('.gallery'), {
                duration: 0.2,
                scrollTo: {y:project},
                ease: 'Power3.in',
            })
        }
        sessionStorage.setItem('projet', '')

        this.footer.classList.remove('absolute')
        this.footer.style.position = 'fixed'
        gsap.timeline()
        .to(this.footer, {
            duration: 1,
            opacity: 1,
            y: 0
        }, '+=0.3')
        
        this.addEvents('on')
        this.detectImage()

    }

    initImage() {

        this.thumbsWrapper.forEach((thumb) => {
            thumb.classList.contains('small') && thumb.classList.remove('small')
            thumb.classList.contains('large') && thumb.classList.remove('large')
        })

        if(window.innerWidth > 800) {

            this.thumbsWrapper.forEach((thumb) => {

                this.countImage == 1 && thumb.classList.add('large')
                this.countImage == 2 && thumb.classList.add('small')
                this.countImage == 3 && thumb.classList.add('small')
                this.countImage == 4 && thumb.classList.add('large')

    
                this.countImage < 4
                ? this.countImage++ : this.countImage = 1
    
            })

        } else {

            this.thumbsWrapper.forEach((thumb) => {

                this.countImage == 1 && thumb.classList.add('large')
                this.countImage == 2 && thumb.classList.add('small')
    
                this.countImage < 2
                ? this.countImage++ : this.countImage = 1
    
            })

        }

    }

    addEvents(type) {

        this.backImage.forEach((figure) => {

            event[type](figure, 'mouseenter', this.handleMouseEnter)

        })

        this.backImage.forEach((figure) => {

            event[type](figure, 'mouseleave', this.handleMouseLeave)

        })

        event[type](window, 'scroll', this.handleScroll)
        event[type](document.querySelector('.gallery'), 'scroll', this.detectImage)

    }

    handleScroll() {

        if(document.querySelector('.intro')) {

            const introTop = document.querySelector('.intro').getBoundingClientRect().top
    
            if(introTop <= window.innerHeight * -1 ) {
    
                document.querySelector('.intro').remove()
                document.querySelector('html').classList.add('intro_done')
                sessionStorage.setItem('intro', 'true');              
    
            }
    

        }

        this.detectImage()

    }

    detectImage() {

        this.thumbs.forEach((thumb, index) => {

            const thumbTop = thumb.getBoundingClientRect().top

            let headerHeight
            window.innerWidth > 1200
            ? headerHeight = 77 : headerHeight = 58

            if( window.innerWidth > 800 ) {

                if(thumbTop == 0 || thumbTop == window.innerHeight - 77 || thumbTop == (window.innerHeight - 77) * -1 || thumbTop < 20) {

                    const image = thumb.querySelector('.background_image')
                    if(image && image?.dataset.srcset) {
                        chargeImageBack(thumb)
                    }
            
                }
            } else {

                if(thumbTop >= -20 && thumbTop < window.innerHeight + (window.innerHeight / 2)) {

                    const image = thumb.querySelector('.background_image')
                    if(image && image?.dataset.srcset) {
                        chargeImageBack(thumb)
                    }
            
                }

                if(thumbTop <= window.innerHeight * -1 && thumbTop > (window.innerHeight + (window.innerHeight / 2) * -1)) {

                    const image = thumb.querySelector('.background_image')
                    if(image && image?.dataset.srcset) {
                        chargeImageBack(thumb)
                    }
            
                }
            }
            
        })
    }

    handleMouseEnter(e) {

        clearTimeout(this.set)
        this.wait = true
        const figure = e.target.closest('.figure')
        const title = figure.dataset.title
        const begin = figure.dataset.begin
        const end = figure.dataset.end

        let typeBrut = figure.dataset.type
        typeBrut = typeBrut.split(', ')

        const infos = document.querySelector('.info_project')

        gsap.timeline()
        .to(infos, {
            duration: 0.5,
            opacity: 0,
            y: -10,
        })


        const titleWrapper = document.querySelector('.info_project_title')
        const beginWrapper = document.querySelector('.info_project_date_begin')
        const endWrapper = document.querySelector('.info_project_date_end')
        const arrow = document.querySelector('.info_project_date_arrow')
        const text = document.querySelector('footer .text_agence')

        titleWrapper.textContent = title
        beginWrapper.textContent = begin

        if(end) {

            arrow.classList.remove('hide')
            endWrapper.textContent = end

        } else {

            arrow.classList.add('hide')
            endWrapper.textContent = ''

        }

        text.classList.add('white') 

        
        // je twiste les infos et la navigation
        const navigation = document.querySelector('.footer .navigation')

        const tl = gsap.timeline()
        .to(navigation, {
            duration: 0.3,
            y: -10,
            opacity: 0
        })
        .to(navigation, {duration: 0, display: 'none'})
        .to(infos, {
            duration: 0.3,
            y: 0,
            opacity: 1
        })
        // .then(() => { arrow.classList.remove('anim')})

        const anchors = [...document.querySelectorAll('.anchor_footer')]
        anchors.forEach((anchor) => {
            const anchorName = anchor.dataset.anchor
            if(typeBrut.find((type) => anchorName == type)) {
                anchor.classList.add('black')
            } else {
                anchor.classList.remove('black')
            }
            
        })

    }

    handleMouseLeave() {

        this.wait = true
        this.set = setTimeout(() => {
            this.wait = false
        },600)

        // je twiste les infos et la navigation
        const navigation = document.querySelector('.footer .navigation')
        const infos = document.querySelector('.info_project')

        setTimeout(() => {

            const text = document.querySelector('footer .text_agence')
            !this.wait && text.classList.remove('white')

            !this.wait && gsap.timeline()
            .to(infos, {
                duration: 0.3,
                y: -10,
                opacity: 0
            })
            .to(navigation, {duration: 0, opacity: 0, display: 'block'})
            // .to(navigation, {duration: 0.2, x: -10})
            .to(navigation, {
                duration: 0.5,
                opacity: 1,
                y: 0
            },'+=0.1')

        }, 600)
        

    }

	beforeDestroy() {

        this.addEvents('off')
        this.handleMouseLeave()

    }

	resize(w) {

        this.mid = (window.innerWidth - 40) / 2
		const oldConfig = config.isMobile        
        configQ(w)
        if (oldConfig !== config.isMobile) {
            this.addEvents('off')
            this.mounted()
        }

	}
}

export default Home